import {mapActions, mapGetters} from "vuex";
import Vue from 'vue';

export default {
  name: "ingredient-page",
  data() {
    return {
      crumbs: [],
    }
  },
  computed: {
    ...mapGetters({
      ingredient: 'pages/ingredient',
      menu: 'setting/headerMenu'
    })
  },
  created() {
    this.getIngredient(this.$route.params.slugIngredient)
  },
  methods: {
    ...mapActions({
      getIngredient: 'pages/GET_INGREDIENT'
    })
  }
}
